
import React, { Component } from 'react'
import GoogleLogin from 'react-google-login';
import API from '../../helpers/api.jsx';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle } from "@fortawesome/free-brands-svg-icons"
import {withRouter} from 'react-router-dom';
import { notify } from '../../helpers/common';
import NotificationAlert from 'react-notification-alert';

var BASEDIR = process.env.REACT_APP_BASEDIR;
const CLIENT_ID = '<your Client ID>';


class GoogleBtn extends Component {
   constructor(props) {
    super(props);

    this.state = {
      isLogined: false,
      accessToken: ''
    };

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
  }

  login (response) {
      console.log("response", response);
    if(response.accessToken){
      this.setState(state => ({
        isLogined: true,
        accessToken: response.tokenId
      }),()=>this.handleLogin());
    }
  }
  handleLogin = async () => {
    
    const { accessToken } = this.state;

      const result = await API.post(`Profiles/googleLogin`, null, { params: {
        token: accessToken
      }});

      if (result.status === 200) {
        const params = result.data;
        localStorage.setItem('WeCodeLand_jwt', params.id);
        localStorage.setItem('WeCodeLand_user', params.userId);
        localStorage.setItem('WeCodeLand_profile', JSON.stringify(params.profile));
        params.profile && params.profile.roles && params.profile.roles.some(item => item.name === "admin") ?
        this.props.history.push({ pathname: BASEDIR + "/admin/dashboard" })
        :
        this.props.history.push({ pathname: BASEDIR + "/dashboard" })
      }
  }

  handleLoginFailure (response) {
    console.log('Failed to log in')
  }


  render() {
    return (
    <div>
      { <GoogleLogin
          clientId={ "5376385561-6bge6jthambe90bahdf2ku38kqrsth7j.apps.googleusercontent.com" }
          buttonText='Login'
          onSuccess={ this.login }
          onFailure={ this.handleLoginFailure }
          cookiePolicy={ 'single_host_origin' }
          responseType='code,token'
          render={renderProps => (
            <button onClick={renderProps.onClick} disabled={renderProps.disabled} className="btn btn-primary btn-block btn_login google_btn"><FontAwesomeIcon className="btn_brand_ico" icon={faGoogle} />Sign in with Google</button>
          )}
        />
      }

    </div>
    )
  }
}

export default withRouter(GoogleBtn);