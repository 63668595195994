import GeneralLayout from 'layouts/General.jsx';
import AdminLayout from 'layouts/AdminLayout.jsx';
import LoginPage from 'layouts/LoginPage.jsx';

let BASEDIR = process.env.REACT_APP_BASEDIR;

var indexRoutes = [
    { path: BASEDIR+"/register", name: "Register", component: LoginPage, authorizedRoles:["all"] },
    { path: BASEDIR + "/reset", name: "Forget password", component: LoginPage , authorizedRoles:["all"] },
    { path: BASEDIR + "/activation", name: "Activation", component: LoginPage , authorizedRoles:["all"] },
    { path: BASEDIR + "/admin", name: "Admin", component: AdminLayout, authorizedRoles:["admin"] },
    { path: BASEDIR+"/", name: "Home", component: GeneralLayout, authorizedRoles:["all"] },
    { path: "/", name: "Home", component: GeneralLayout, authorizedRoles:["all"] },
];

export default indexRoutes;
