import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupAddon,
  Input
} from "reactstrap";
import API from '../../helpers/api.jsx'
/* import Loader from "react-loader-spinner";
import { members } from 'variables/general/members.jsx'; */
import { notify } from "../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";
import empty_pic_room from "assets/img/default-logo.png";
/* import { ExportToCsv } from "export-to-csv"; */
import ModalConfirmation from "../../components/modals/ModalConfirmation.jsx";
import ReactPaginate from 'react-paginate';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-daterange-picker/dist/css/react-calendar.css'

import originalMoment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(originalMoment);

var BASEDIR = process.env.REACT_APP_BASEDIR;
var IMGDIR = process.env.REACT_APP_IMGDIR;
var SERVERDIR = process.env.REACT_APP_API;

class ClassroomRatings extends React.Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      sessions: [],
      limit: 20,
      count: 0,
      totalCount: 0,
      active:
        (this.props.match.params && Number(this.props.match.params.page)) || 1,
      loading: true,
      classroomToPreview: {},
      actionValue: false,
      modalConfirmRevoke: false,
      modalConfirmMute: false,
      modalChangeRole: false,
      id: "",
      idClassroomToAccept: "",
      indexRevoke: "",
      activated: false,
      modalProfile: false,
      modalConfirmAction: false,
      idSession: "",
      actionValue: false,
      selectedSessionId: "",
      text: "",
      role: "",
      csvData: [],
      userProfile: JSON.parse(localStorage.getItem("WeCodeLand_profile")),
      tags: [],
      modalFeedBack: false,
      feedbacksToPreview: [],
      searchQuery: ""
    };
  }

  async componentWillMount() {
    await this.getSessions(this.state.active);
    console.log("hello", this.state.sessions);
  }

  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  };

  updateInputValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
 

  //**  CHANGE INPUT VALUE*/
  handleInputChange = async (e) => {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  getSessions = async (key) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      this.setState({
        loading: true,
        isSearchResult: false,
        searchQuery: ""
      });
      const sessions = await API.get(
        `Rooms/ratingList?limit=${limit}&skip=${skip}&access_token=${accessToken}`
      );

      if (sessions.status === 200) {
        console.log("sessions content :", sessions.data);
        const pageCount = Math.ceil(sessions.data.count / this.state.limit);
        this.setState({ count: pageCount, totalCount: sessions.data.count });

        this.setState(
          {
            csvData: sessions.data.room,
            active: key,
            loading: false,
            sessions: sessions.data.room,
          },
          () => {}
        );
        // this.props.history.push('/dashboard/');
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      console.log("error: ", error);
    }
  };
 

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    if (this.state.isSearchResult) {
      this.getSearchedSessions(selectedPage + 1);
    } else {
      this.getSessions(selectedPage + 1);
    }

    window.history.pushState(
      null,
      null,
      BASEDIR + `/admin/classroomRatings/${selectedPage + 1}`
    );
  };

  toggleModalConfirmAction= (classroomId,value) => {
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction,
      actionValue: value,
      idClassroomToAccept: classroomId
    })
  }
  closeModalConfirmAction= () => {
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction
    })
  }
  toggleModalProfile= (classroom) => {
    this.setState({
      modalProfile: !this.state.modalProfile,
      classroomToPreview : classroom
    })
  }
  closeModalProfile= () => {
    this.setState({
      modalProfile: !this.state.modalProfile
    })
  }
  openModalFeedBack=(feedbacks) =>{
    this.setState({
        modalFeedBack: !this.state.modalFeedBack,
        feedbacksToPreview: feedbacks
      })
  }
  closeModalFeedBack=() =>{
    this.setState({
        modalFeedBack: !this.state.modalFeedBack,
      })
  }


  handleStatusChange = async()=>{
    const selectedSessionId = this.state.idClassroomToAccept;
    const value = this.state.actionValue;
    try {
      let result = await API.post(`FeedbackRooms/hideShow`, null, { params: {
        feedId: selectedSessionId, value: value, access_token: localStorage.getItem("WeCodeLand_jwt")
      }})
      if (result.status === 204) {
        console.log("Action done SUCCESSFULLY");
        notify(
          { type: "success", message: !value ? "Feedback published" : "Feedback hidden" },
          this
        );
        this.getSessions(this.state.active);
        this.setState({
          loading: false,
          modalConfirmAction: !this.state.modalConfirmAction
        })
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      await this.setState({
        loading: false
      });
      notify({ type: "danger", message: message }, this);
    } finally {
    }
  }

  renderStringTags = (tagsList) => {
    if (tagsList && tagsList.length > 0) {
      return (
        <div>
          {tagsList.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
            </li>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
  };
  renderFileNames = (attachments) => {
    if (attachments && attachments.length > 0) {
      return (
        <div>
          {attachments.map((file, index) => (
            <li key={index} className="tag">
              <span className="tag-title underlined-clickable" onClick={() => window.open(`${SERVERDIR}Container2s/file/download/${file && file.name}`, "_blank")}>{file && file.originalFilename}</span>
            </li>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
  };
  renderImageNames = (attachments) => {
    if (attachments && attachments.length > 0) {
      return (
        <div>
          {attachments.map((file, index) => (
            <li key={index} className="tag">
              <span className="tag-title underlined-clickable" onClick={() => window.open(`${SERVERDIR}Containers/img/download/${file && file.name}`, "_blank")}>{file && file.originalFilename}</span>
            </li>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
    };

  render() {
    /* if (this.state.loading)
      return (
        <div
          className="content-fluid h-100 d-flex justify-content-center align-items-center"
          style={{
            marginTop: "30%",
            position: "relative",
            center: "calc(50% - 50px)",
            top: "50%"
          }}
        >
          <Loader
            type="ThreeDots"
            color="#00BFA5"
            height={90}
            width={90}
            timeout={300000}
          />
        </div>
      ); */
    const {
      sessions,
      actionValue,
      classroomToPreview
    } = this.state;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Classroom Ratings</h1>
                </div>
              </div>
              {/* /////// */}
              <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                <section className="box ">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        {/* <span
                          className="export-button"
                          onClick={() => this.exportData()}
                        >
                          <i className="fa fa-external-link"></i>&nbsp;Export
                        </span> */}
                        {/* {this.state.isSearchResult ? (
                          <span className="results-count">
                            {this.state.totalCount} results were found
                          </span>
                        ) : null} */}
                        <Table hover responsive className="members-table">
                          <thead>
                            <tr>
                              <th className="fullName">Classroom</th>
                              <th className="centered-column-text">User</th>
                              <th className="centered-column-text">Rating</th>
                              <th className="centered-column-text">Feedback</th>
                              <th className="centered-column-text">Rated on</th>
                              <th className="centered-column-text">
                                Hide/Show
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log(sessions)}

                            {sessions.map((session, key) => {
                              const userProfile = session && session.feedProfile && session.feedProfile.id;
                              const feedbackArray = session && session.feedRoom && session.feedRoom.feedbacks && session.feedRoom.feedbacks.filter(x => x.profileId === userProfile);
                              
                              return (
                                <tr key={key}>
                                  <td
                                    className="user-inline-img text-cell"
                                    id={`Tooltip${key}`}
                                  >
                                    <img
                                      src={
                                        session.feedRoom && session.feedRoom.logo && session.feedRoom.logo.name
                                          ? `${SERVERDIR}Containers/img/download/${session.feedRoom.logo.name}`
                                          : empty_pic_room
                                      }
                                      alt="user avatar"
                                      className="avatar-image img-inline"
                                    />
                                      {session && session.feedRoom && session.feedRoom.name}
                                  </td>
                                  <td className="centered-column-text text-cell">
                                    {session && session.feedProfile && session.feedProfile.fullName}
                                  </td>
                                  <td className="centered-column-text text-cell clickable">
                                    <span className="purple">{session && session.value} stars</span>
                                  </td>
                                  <td className="centered-column-text text-cell clickable" onClick={(feedbackArray && feedbackArray.length>0)? ()=>this.openModalFeedBack(feedbackArray) : null}>
                                    {(feedbackArray && feedbackArray.length>0)? <span className="purple">{feedbackArray && feedbackArray.length} feedbacks</span>:null}
                                  </td>
                                  <td className="centered-column-text text-cell">
                                    {moment(session && session.createdAt).format(
                                      "D MMM YYYY"
                                    )}
                                  </td>
                                  <td className="centered-column-text text-cell">
                                        <span
                                      /* style={{
                                        cursor: "pointer",
                                        color: session.feedRoom && session.feedRoom.feedbacks && session.feedRoom.feedbacks.length>0
                                          ? "red"
                                          : "green",
                                        padding: "5px"
                                      }} */
                                      /* onClick={()=>this.openModalBlock(key,profile.revoked)} */
                                    >
                                      <i
                                        onClick={feedbackArray && feedbackArray.length>0 ? ()=> this.toggleModalConfirmAction(feedbackArray[0].id,!feedbackArray[0].deleted) :null}
                                        className={feedbackArray && feedbackArray.length>0 && feedbackArray[0].deleted=== true ? "fa fa-eye clickable text-success" : feedbackArray && feedbackArray.length>0 && feedbackArray[0].deleted===false ? "fa fa-eye-slash clickable text-danger" :  "fa fa-eye disabled"}
                                        style={{ fontSize: "20px" }}
                                      ></i>
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* /////// */}
              {this.state.count > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.count}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.active - 1}
                  forcePage={this.state.active - 1}
                />
              ) : null}
            </Col>
          </Row>
        </div>

        <div>
        <ModalConfirmation
            isOpen={this.state.modalConfirmAction}
            toggle={this.closeModalConfirmAction}
            disabledButton={false}
            confirm={this.handleStatusChange}
            cancel={this.closeModalConfirmAction}
            title={!actionValue ? "Publish Feedback" : "Hide Feedback"}
            body={
              !actionValue
                ? "Are you sure you want to publish this feedback?"
                : "Are you sure you want to hide this feedback?"
            }
            confirmText={!actionValue ? "Publish" : "Hide"}
          />
        </div>
        <Modal
      isOpen={this.state.modalFeedBack}
      toggle={this.closeModalFeedBack}
    >
      <ModalHeader
        toggle={this.closeModalFeedBack}
      >
        <span className="bold">Feedbacks</span>
      </ModalHeader>
      <ModalBody>
        <div>
        {this.state.feedbacksToPreview.map((feedback, key) => {
                        return (
          <div>
              {key === 0 ? null : <hr/>}
              <span
               key={key}
               className="tags-listing">
                {feedback.content}<br/>
              </span>
          </div>)})}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          style={{ height: "35px", width: "98px", padding: "0px" }}
          color="primary"
          onClick={this.closeModalFeedBack}
        >
          Ok
        </Button>
      </ModalFooter>
    </Modal>
      </div>
    );
  }
}

export default ClassroomRatings;
