import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import {
    Router,
    Route,
    Switch,
    Redirect
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'assets/scss/zest-admin.css';
import 'assets/fonts/simple-line-icons.css';
import 'assets/fonts/AmpleSoft.css';

import indexRoutes from 'routes/index.jsx';
import LoginPage from "./layouts/LoginPage";
import PrivateRoute from "./helpers/privateRoute";

let BASEDIR = process.env.REACT_APP_BASEDIR;

const hist = createBrowserHistory();

ReactDOM.render(

    <Router history={hist} basename={process.env.REACT_APP_BASEDIR}>
        <Switch>
            <Route exact path="/" render={(props) => (
                <Redirect to="/app/login"/>
            )}/>
            <Route exact path={BASEDIR} render={(props) => (
                <Redirect to="/app/login"/>
            )}/>
            <Route
                path={BASEDIR + "/login"}
                name={"Login"}
                component={LoginPage}
            />

            <Route
                path={BASEDIR + "/register"}
                name={"register"}
                component={LoginPage}
            />
            <Route
                path={BASEDIR + "/cgu"}
                name={"cgu"}
                component={LoginPage}
            />
            <Route
                path={BASEDIR + "/reset"}
                name={"reset"}
                component={LoginPage}
            />
            <Route
                path={BASEDIR + "/activation"}
                name={"activation"}
                component={LoginPage}
            />
            {
                indexRoutes.map((prop, key) => {
                    {console.log('indexRoutes',indexRoutes)}
                    return (
                        <PrivateRoute
                            name={prop.name}
                            path={prop.path}
                            key={key}
                            component={prop.component}
                            authorizedRoles={prop.authorizedRoles}
                            to={BASEDIR + "/login"}
                        />
                    );
                })
            }
            

        </Switch>
    </Router>
    , document.getElementById('root'));
