import AdminGeneral from 'views/admin/AdminGeneral.jsx';
import MembersManagement from 'views/admin/MembersManagement.jsx';
import SessionManagement from 'views/admin/SessionManagement.jsx';
import CourseManagement from 'views/admin/CourseManagement.jsx';
import ClassroomManagement from 'views/admin/ClassroomManagement.jsx';
import SessionRatings from 'views/admin/SessionRatings.jsx';
import TutorRatings from 'views/admin/TutorRatings.jsx';
import ClassroomRatings from 'views/admin/ClassroomRatings.jsx';
import StudentsStatistics from 'views/admin/statistics/StudentsStatistics.jsx';
import CoursesStatistics from 'views/admin/statistics/CoursesStatistics.jsx';
import PromoCodeManagement from 'views/admin/PromoCodeManagement.jsx';
import NewCourse from 'views/admin/NewCourse.jsx';
import EditSession from 'views/admin/EditSession.jsx';
import EditCourse from 'views/admin/EditCourse.jsx';
import Login from 'views/general/Pages/Login.jsx';
import Register from 'views/general/Pages/Register.jsx';
import MembersTracking from 'views/admin/MembersTracking.jsx';

//import {menuStyle} from 'variables/settings/blog.jsx';

var BASEDIR = process.env.REACT_APP_BASEDIR;

var dashRoutes = [

        { path: BASEDIR+"/admin/dashboard", component: AdminGeneral, type: "child",authorizedRoles: ["admin"]},
        { path: BASEDIR+"/login", component: Login, type: "child",authorizedRoles: ["admin"]},
        { path: BASEDIR+"/register", component: Register, type: "child",authorizedRoles: ["admin"]},
        { path: BASEDIR+"/admin/newCourse", component: NewCourse, type: "child",authorizedRoles: ["admin"]},
        { path: BASEDIR+"/admin/editCourse/:id", component: EditCourse, type: "child",authorizedRoles: ["admin"]},
        { path: BASEDIR+"/admin/editSession/:id", component: EditSession, type: "child",authorizedRoles: ["admin"]},

        
        {
            path: BASEDIR+"/admin/dashboard",
            name: "Home",
            icon: "home",
            component: AdminGeneral,
            authorizedRoles: ["admin"]
          },
        {
            path: BASEDIR + "/admin/membersManagement/:page?",
            name: "Members Management",
            icon: "ui-faicon i-people",
            component: MembersManagement,
            authorizedRoles: ["admin"]
          },
        {
            path: BASEDIR + "/admin/sessionManagement/:page?",
            name: "Session Management",
            icon: "ui-faicon i-graduation",
            component: SessionManagement,
            authorizedRoles: ["admin"]
          },
        {
            path: BASEDIR + "/admin/courseManagement/:page?",
            name: "Course Management",
            icon: "ui-faicon i-book-open",
            component: CourseManagement,
            authorizedRoles: ["admin"]
          },
        {
            path: BASEDIR + "/admin/classroomManagement/:page?",
            name: "Classroom Management",
            icon: "ui-faicon i-location-pin",
            component: ClassroomManagement,
            authorizedRoles: ["admin"]
          },
          {
            path: BASEDIR + "/admin/promoCodeManagement/:page?",
            name: "WeCoin Management",
            icon: "ui-faicon i-wallet",
            component: PromoCodeManagement,
            authorizedRoles: ["admin"]
          },
          {
            path: BASEDIR + "/admin/membersTracking/:page?",
            name: "Members Tracking",
            icon: "ui-faicon i-list",
            component: MembersTracking,
            authorizedRoles: ["admin"]
          },
        {
            path: BASEDIR + "/admin/sessionRatings/:page?",
            name: "Session Ratings",
            icon: "ui-faicon i-star",
            component: SessionRatings,
            type: "child",
            authorizedRoles: ["admin"]
          },
        {
            path: BASEDIR + "/admin/tutorRatings/:page?",
            name: "Tutor Ratings",
            icon: "ui-faicon i-star",
            component: TutorRatings,
            type: "child",
            authorizedRoles: ["admin"]
          },
        {
            path: BASEDIR + "/admin/classroomRatings/:page?",
            name: "Classroom Ratings",
            icon: "ui-faicon i-star",
            component: ClassroomRatings,
            type: "child",
            authorizedRoles: ["admin"]
          },
          {
            path: BASEDIR + "/admin/studentsStatistics",
            name: "Students Statistics",
            icon: "ui-faicon i-graph",
            component: StudentsStatistics,
            type: "child",
            authorizedRoles: ["admin"]
          },
          {
            path: BASEDIR + "/admin/coursesStatistics",
            name: "Courses Statistics",
            icon: "ui-faicon i-graph",
            component: CoursesStatistics,
            type: "child",
            authorizedRoles: ["admin"]
          },
          {
            name: "Statistics",
            icon: "ui-faicon i-graph",
            authorizedRoles: ["admin"],
            type: "dropdown",
            child: [
              { path: BASEDIR + "/admin/studentsStatistics", name: "Students Statistics" },
              { path: BASEDIR + "/admin/coursesStatistics", name: "Courses Statistics" }
            ]
          },
          {
            name: "Rating Management",
            icon: "ui-faicon i-star",
            authorizedRoles: ["admin"],
            type: "dropdown",
            child: [
              { path: BASEDIR + "/admin/sessionRatings/1", name: "Session Ratings" },
              { path: BASEDIR + "/admin/tutorRatings/1", name: "Tutor Ratings" },
              { path: BASEDIR + "/admin/classroomRatings/1", name: "Classroom Ratings" },
            ]
          },
          
          
    { redirect: true, path: BASEDIR+"/admin", pathTo: BASEDIR+"/admin/dashboard", name: "Dashboard" },
    { redirect: true, path: "/admin", pathTo: BASEDIR+"/admin/dashboard", name: "Dashboard" }

];
export default dashRoutes;
