import React from "react";
import {
  Button,
  Row,
  Col,
  Table,
  InputGroup,
  InputGroupAddon,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import API from "./../../helpers/api.jsx";
/* import Loader from "react-loader-spinner"; */
// import { members } from 'variables/general/members.jsx';
import moment from "moment";
import { notify, tunGovList, libyaGovList } from "./../../helpers/common.jsx";
import NotificationAlert from "react-notification-alert";
import { ExportToCsv } from "export-to-csv";
import ModalConfirmation from "./../../components/modals/ModalConfirmation.jsx";
import ModalTags from "./../../components/modals/ModalTags.jsx";
import ReactPaginate from "react-paginate";
import ModalProfilePreview from "../../components/modals/ModalProfilePreview.jsx";
var BASEDIR = process.env.REACT_APP_BASEDIR;
var IMGDIR = process.env.REACT_APP_IMGDIR;
var SERVERDIR = process.env.REACT_APP_API;
const roles = ["Role", "student", "teacher", "host", "admin"];
const genders = ["All Users", "Male", "Female"];
const govList = [
  "Governorate",
  "--> Tunisia <--",
  ...tunGovList,
  "--> Libya <--",
  ...libyaGovList,
];
const options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
};
const csvExporter = new ExportToCsv(options);

class MembersManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profiles: [],
      limit: 20,
      count: 0,
      active:
        (this.props.match.params && Number(this.props.match.params.page)) || 1,
      loading: true,
      modalTags: false,
      modalBlock: false,
      modalConfirmRevoke: false,
      modalConfirmMute: false,
      modalChangeRole: false,
      id: "",
      indexMute: "",
      indexRevoke: "",
      upgradeValue: false,
      tooltipOpen: false,
      modalConfirmAction: false,
      idProfile: "",
      indexAction: "",
      text: "",
      role: "",
      csvData: [],
      userProfile: JSON.parse(localStorage.getItem("WeCodeLand_profile")),
      tags: [],
      modalTagsTitle: "",
      isSearchResult: false,
      searchQuery: "",
      isFilterResult: false,
      filter: {
        gender: "",
        country: "",
        role: "",
      },
      profileUpgrade: {},
      newRole: "",
      modalProfile: false,
      modalDelete: false,
      indexToDelete: "",
      profilePreview: {},
      profileUpgradeDetails: {},
      totalCount: 0,
      profileToPreview: {},
      modalPreviewProfileVisible: false
    };
  }

  async componentWillMount() {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    API.defaults.headers.common["Authorization"] = accessToken;
    await this.getProfiles(this.state.active);
    console.log("hello", this.state.profiles);
    /* this.getCount();

    console.log("my role", this.state.userProfile.role); */
  }

  handleDeleteProfile = async () => {
    const { profiles, limit, indexToDelete } = this.state;

    this.setState({
      loading: true,
      isSearchResult: false,
      isFilterResult: false,
    });

    try {
      // delete profile
      const deletedProfile = await API.delete(
        `Profiles/deleteProfile?profileId=${profiles[indexToDelete].id}`
      );

      if (deletedProfile.status === 200) {
        // remove profile locally
        profiles.splice(indexToDelete, 1);
        // re-count the pagination length
        const pageCount = Math.ceil(profiles.length / limit);
        this.setState(
          {
            csvData: profiles,
            loading: false,
            profiles: profiles,
            count: pageCount,
            modalDelete: false,
          },
          () => {}
        );
        notify({ type: "success", message: "User deleted" }, this);
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      console.log("error: ", error);
    }
  };

  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  };

  updateInputValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleGendersInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          gender: target.value,
        },
      },
      () => this.getFilteredProfiles(1)
    );
  };
  handleGovernorateInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          country: target.value,
        },
      },
      () => this.getFilteredProfiles(1)
    );
  };
  handleRolesInputChange = (e) => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          role: target.value,
        },
      },
      () => this.getFilteredProfiles(1)
    );
  };

  /* toggleTooltip=(target)=> {

    this.setState({
      [target]: !this.state[target]
    });
  } */

  //Export DATA

  exportData = async () => {
    if (this.state.isSearchResult === false || this.state.isFilterResult) {
      const profiles = await API.get(`Profiles/export`, {
        params: {
          access_token: localStorage.getItem("WeCodeLand_jwt"),
          filters: this.state.filter,
          type: "filter",
          all: true,
        },
      });

      if (profiles.status === 200) {
        csvExporter.generateCsv(profiles.data);
      } else {
        return;
      }
    } else {
      const profiles = await API.get(`Profiles/export`, {
        params: {
          access_token: localStorage.getItem("WeCodeLand_jwt"),
          filters: this.state.searchQuery,
          type: "search",
          all: true,
        },
      });

      if (profiles.status === 200) {
        csvExporter.generateCsv(profiles.data);
      } else {
        return;
      }
    }
  };

  //**  CHANGE INPUT VALUE*/
  handleInputChange = async (e) => {
    const target = e.target;
    const name = target.name;

    this.setState({
      [name]: target.value,
    });
  };

  getProfiles = async (key) => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const limit = this.state.limit;
    const skip = (key - 1) * this.state.limit;
    try {
      this.setState({
        loading: true,
        isSearchResult: false,
        isFilterResult: false,
        searchQuery: "",
      });
      const profiles = await API.get(
        `Profiles/getListUsers?limit=${limit}&skip=${skip}&access_token=${accessToken}`
      );

      if (profiles.status === 200) {
        console.log("profiles content :", profiles.data);
        const pageCount = Math.ceil(profiles.data.count / this.state.limit);
        this.setState({ count: pageCount });

        this.setState(
          {
            csvData: profiles.data.users,
            active: key,
            loading: false,
            profiles: profiles.data.users,
            totalCount: profiles.data.count,
          },
          () => {}
        );
        // this.props.history.push('/dashboard/');
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
      console.log("error: ", error);
    }
  };

  cancelSearch = () => {
    this.setState({ text: "", active: 1 }, () =>
      window.history.pushState(
        null,
        null,
        BASEDIR + `/admin/membersManagement/${1}`
      )
    );
    this.getProfiles(1);
  };

  ///Search
  getSearchedProfiles = async (key) => {
    const limit = this.state.limit;
    const text = this.state.text;
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    if (text === "") {
      this.getProfiles(1);
      return;
    } else {
      try {
        this.setState({
          loading: true,
          isSearchResult: true,
          isFilterResult: false,
          searchQuery: text,
          filter: {
            gender: "",
            country: "",
            role: "",
          },
        });
        const profiles = await API.get(
          `Profiles/search?text=${text}&skip=${
            (key - 1) * limit
          }&limit=${limit}&access_token=${accessToken}`
        );

        if (profiles.status === 200) {
          const pageCount = Math.ceil(profiles.data.count / this.state.limit);
          this.setState({ count: pageCount });
          console.log("profiles searched content :", profiles.data.profiles);

          this.setState(
            {
              csvData: profiles.data.profiles,
              active: key,
              loading: false,
              profiles: profiles.data.profiles,
              totalCount: profiles.data.count,
            },
            () => {}
          );
          // this.props.history.push('/dashboard/');
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("error: ", error);
      }
    }
  };
  ///Filter
  getFilteredProfiles = async (key) => {
    const limit = this.state.limit;
    const { filter } = this.state;
    var search = encodeURIComponent(JSON.stringify(filter));
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    if (
      this.checkProperties(filter) ||
      filter.country === "Governorate" ||
      filter.gender === "All Users" ||
      filter.role === "Role"
    ) {
      if (filter.country === "Governorate") {
        this.setState(
          {
            filter: {
              ...this.state.filter,
              country: "",
            },
          },
          () => {
            this.getFilteredProfiles(1);
            return;
          }
        );
      }
      if (filter.gender === "All Users") {
        this.setState(
          {
            filter: {
              ...this.state.filter,
              gender: "",
            },
          },
          () => {
            this.getFilteredProfiles(1);
            return;
          }
        );
      }
      if (filter.role === "Role") {
        this.setState(
          {
            filter: {
              ...this.state.filter,
              role: "",
            },
          },
          () => {
            this.getFilteredProfiles(1);
            return;
          }
        );
      } else {
        this.getProfiles(1);
        return;
      }
    } else {
      try {
        this.setState({
          loading: true,
          isFilterResult: true,
          isSearchResult: false,
          searchQuery: "",
          text: "",
        });
        const profiles = await API.get(
          `Profiles/searchBy?text=${search}&skip=${
            (key - 1) * limit
          }&limit=${limit}&access_token=${accessToken}`
        );

        if (profiles.status === 200) {
          const pageCount = Math.ceil(profiles.data.count / this.state.limit);
          this.setState({ count: pageCount });
          console.log("profiles searched content :", profiles.data.profiles);

          this.setState(
            {
              csvData: profiles.data.profiles,
              active: key,
              loading: false,
              profiles: profiles.data.profiles,
              totalCount: profiles.data.count,
            },
            () => {}
          );
          // this.props.history.push('/dashboard/');
        } else {
          this.setState({
            loading: false,
          });
        }
      } catch (error) {
        this.setState({
          loading: false,
        });
        console.log("error: ", error);
      }
    }
  };

  /* toggleModalConfirmRevoke = (id, isRevoked, key) => {
    this.setState({
      modalConfirmRevoke: !this.state.modalConfirmRevoke,
      id: id,
      isRevoked: isRevoked,
      indexRevoke: key
    });
  }; */
  toggleModalTags = (tags, modalTagsTitle) => {
    this.setState({
      modalTags: !this.state.modalTags,
      tags: tags,
      modalTagsTitle: modalTagsTitle,
    });
  };
  toggleModalProfile = (profile) => {
    this.setState({
      modalProfile: !this.state.modalProfile,
      profilePreview: profile,
      profileUpgradeDetails:
        profile &&
        profile.upgradeRequest &&
        profile.upgradeRequest.length > 0 &&
        profile.upgradeRequest.filter((obj) => {
          return obj.status === "Pending";
        }).length > 0 &&
        profile.upgradeRequest.filter((obj) => {
          return obj.status === "Pending";
        })[0],
    });
  };
  openModalProfile = () => {
    this.setState({
      modalProfile: !this.state.modalProfile,
    });
  };
  closeModalTags = () => {
    this.setState({
      modalTags: !this.state.modalTags,
    });
  };
  toggleBlock = () => {
    const { profiles } = this.state;
    profiles[this.state.indexBlock].revoked = !this.state.revoked;
    this.setState(
      {
        profiles: profiles,
        id: this.state.profiles[this.state.indexBlock].id,
        modalBlock: !this.state.modalBlock,
      },
      () => this.handleRevoke()
    );
  };
  openModalBlock = (key, revoked) => {
    this.setState({
      modalBlock: !this.state.modalBlock,
      revoked: revoked,
      indexBlock: key,
    });
  };
  closeModalBlock = () => {
    this.setState({
      modalBlock: !this.state.modalBlock,
    });
  };

  /* toggleModalConfirmMute = (id, isMuted, key) => {
    console.log("id !!", id);

    this.setState({
      id: id,
      isMuted: isMuted,
      modalConfirmMute: !this.state.modalConfirmMute,
      indexMute: key
    });
  };
  toggleModalConfirmAction = (id, value, key) => {
    this.setState({
      modalConfirmAction: !this.state.modalConfirmAction,
      idProfile: id,
      upgradeValue: value,
      indexAction: key
    });
  };
  toggleModalChangeRole = (id, key) => {
    if (this.state.userProfile.role === "admin") {
      this.setState({
        role: this.normalizeName(
          this.state.profiles[key].role,
          this.state.profiles[key].oldRole
        ),
        modalChangeRole: !this.state.modalChangeRole,
        idProfile: id,
        indexAction: key
      });
    } else {
      return;
    }
  };
  toggleModalChangeRoleSimple = () => {
    this.setState({
      modalChangeRole: !this.state.modalChangeRole
    });
  };
  handleChangeRole = async () => {
    const idProfile = this.state.idProfile;
    // const request = {role : this.state.role};
    const request = { profileId: idProfile, role: this.state.role };
    try {
      // let result = await axios.patch(
      //   SERVERDIR + `Profiles/${idProfile}` , request );
      let result = await axios.patch(
        SERVERDIR +
          `Profiles/updateRole?profileId=${idProfile}&role=${this.state.role}`
      );
      if (result.status === 200) {
        console.log("Updated SUCCESSFULLY");
        notify({ type: "success", message: "User's role updated" }, this);
        const params = result.data;
        console.log("params :", params);

        const { profiles, indexAction } = this.state;

        profiles[indexAction].role = result.data.role;

        this.setState({
          loading: false,
          modalChangeRole: !this.state.modalChangeRole,
          profiles: profiles,
          idProfile: ""
        });
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      await this.setState({
        loading: false
      });
      notify({ type: "danger", message: message }, this);
    } finally {
    }
  };

  handleMute = async () => {
    console.log(this.state.id);
    const isMuted = this.state.isMuted;
    const request = this.state.id;
    const value = !this.state.isMuted;
    try {
      let result = await axios.post(
        SERVERDIR + `Profiles/muteUser?userId=` + request + `&value=` + value
      );
      if (result.status === 200) {
        console.log("MUTED SUCCESSFULLY");
        notify(
          { type: "success", message: isMuted ? "User Muted" : "User Unmuted" },
          this
        );
        const params = result.data;
        console.log("params :", params);

        const { profiles, indexMute } = this.state;

        profiles[indexMute].muted = value;
        if (value === true) {
          profiles[indexMute].role = "muted";
        } else {
          profiles[indexMute].role = profiles[indexMute].oldRole;
        }

        this.setState({
          loading: false,
          modalConfirmMute: !this.state.modalConfirmMute,
          profiles: profiles,
          id: ""
        });
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message }
          }
        } = {}
      } = error;
      await this.setState({
        loading: false
      });
      notify({ type: "danger", message: message }, this);
    } finally {
    }
  }; */

  toggleModalConfirmAction = (profile, value) => {
    this.setState({
      modalChangeRole: !this.state.modalChangeRole,
      profileUpgrade: profile,
      upgradeValue: value,
      newRole:
        profile &&
        profile.upgradeRequest &&
        profile.upgradeRequest.length > 0 &&
        profile.upgradeRequest.filter((obj) => {
          return obj.status === "Pending";
        }).length > 0 &&
        profile.upgradeRequest.filter((obj) => {
          return obj.status === "Pending";
        })[0].newRole,
    });
  };
  toggleModalDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
    });
  };
  openModalDelete = (index) => {
    this.setState({
      indexToDelete: index,
      modalDelete: !this.state.modalDelete,
    });
  };

  handleChangeRole = async () => {
    const profile = this.state.profileUpgrade;
    const newRole = this.state.newRole;
    const profileId = profile.id;
    const value = this.state.upgradeValue;
    if (newRole === "host") {
      try {
        let result = await API.post(`Profiles/upgradeToHost`, null, {
          params: {
            value: value,
            profileId: profileId,
          },
        });
        if (result.status === 200) {
          notify({ type: "success", message: "User upgraded to host" }, this);

          this.setState(
            {
              loading: false,
              modalChangeRole: !this.state.modalChangeRole,
              profileUpgrade: {},
            },
            () => this.getProfiles(this.state.active)
          );
        } else {
          throw result.status;
        }
      } catch (error) {
        const {
          response: {
            data: {
              error: { message },
            },
          } = {},
        } = error;
        await this.setState({
          loading: false,
        });
        notify({ type: "danger", message: message }, this);
      } finally {
      }
    } else if (newRole === "teacher") {
      try {
        let result = await API.post(`Profiles/upgrade`, null, {
          params: {
            value: value,
            profileId: profileId,
          },
        });
        if (result.status === 200) {
          notify({ type: "success", message: "User upgraded to tutor" }, this);

          this.setState(
            {
              loading: false,
              modalChangeRole: !this.state.modalChangeRole,
              profileUpgrade: {},
            },
            () => this.getProfiles(this.state.active)
          );
        } else {
          throw result.status;
        }
      } catch (error) {
        const {
          response: {
            data: {
              error: { message },
            },
          } = {},
        } = error;
        await this.setState({
          loading: false,
        });
        notify({ type: "danger", message: message }, this);
      } finally {
      }
    } else {
      notify({ type: "danger", message: "something went wrong" }, this);
      this.setState({
        modalChangeRole: !this.state.modalChangeRole,
      });
    }
  };

  handleRevoke = async () => {
    console.log(this.state.id);
    const request = this.state.id;
    const value = !this.state.revoked;
    try {
      console.log("this.state.id", this.state.id);

      let result = await API.post(
        `Profiles/revokeUser?userId=${request}&value=${value}&access_token=${localStorage.getItem(
          "WeCodeLand_jwt"
        )}`
      );

      if (result.status === 200) {
        console.log("MUTED SUCCESSFULLY");
        notify(
          {
            type: "success",
            message: value ? "User Blocked" : "User Unblocked",
          },
          this
        );
        const params = result.data;
        console.log("params :", params);

        const { profiles, indexBlock } = this.state;
        profiles[indexBlock].revoked = value;
        this.setState({
          loading: false,
          profiles: profiles,
          id: "",
        });
      } else {
        throw result.status;
      }
    } catch (error) {
      const {
        response: {
          data: {
            error: { message },
          },
        } = {},
      } = error;
      await this.setState({
        loading: false,
      });
      notify({ type: "danger", message: message }, this);
    } finally {
    }
  };

  badgeColor = (type, newRole) => {
    if (type === "student" && newRole !== "host" && newRole !== "teacher") {
      return "#3f3f3f";
    } else if (
      (type === "teacher" && newRole !== "host") ||
      type === "teacher/host" ||
      newRole === "teacher"
    ) {
      return "#d43273";
    } else if (
      (type === "host" && newRole !== "teacher") ||
      newRole === "host"
    ) {
      return "#7a3781";
    } else {
      return "grey";
    }
  };
  nameColor = (newRole) => {
    if (newRole === "teacher") {
      return "#d43273";
    } else if (newRole === "host") {
      return "#7a3781";
    } else {
      return "inherit";
    }
  };

  handlePageClick = (data) => {
    const selectedPage = data.selected;
    if (this.state.isSearchResult) {
      this.getSearchedProfiles(selectedPage + 1);
    } else if (this.state.isFilterResult) {
      this.getFilteredProfiles(selectedPage + 1);
    } else {
      this.getProfiles(selectedPage + 1);
    }

    window.history.pushState(
      null,
      null,
      BASEDIR + `/admin/membersManagement/${selectedPage + 1}`
    );
  };
  renderObjectTags = (tagsList) => {
    if (tagsList && tagsList.length > 0) {
      return (
        <div>
          {tagsList.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag.name}</span>
            </li>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };
  renderStringTags = (tagsList) => {
    if (tagsList && tagsList.length > 0) {
      return (
        <div>
          {tagsList.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag}</span>
            </li>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };
  renderExpertiseTags = (tagsList) => {
    if (tagsList && tagsList.length > 0) {
      return (
        <div>
          {tagsList.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">{tag && tag.name}</span>
            </li>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };
  renderFileNames = (attachments) => {
    if (attachments && attachments.length > 0) {
      return (
        <div>
          {attachments.map((file, index) => (
            <li key={index} className="tag">
              <span
                className="tag-title underlined-clickable"
                onClick={() =>
                  window.open(
                    `${SERVERDIR}Container2s/file/download/${
                      file && file.name
                    }`,
                    "_blank"
                  )
                }
              >
                {file && file.originalFilename}
              </span>
            </li>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };
  renderImageNames = (attachments) => {
    if (attachments && attachments.length > 0) {
      return (
        <div>
          {attachments.map((file, index) => (
            <li key={index} className="tag">
              <span
                className="tag-title underlined-clickable"
                onClick={() =>
                  window.open(
                    `${SERVERDIR}Containers/img/download/${file && file.name}`,
                    "_blank"
                  )
                }
              >
                {file && file.originalFilename}
              </span>
            </li>
          ))}
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    /* if (this.state.loading)
      return (
        <div
          className="content-fluid h-100 d-flex justify-content-center align-items-center"
          style={{
            marginTop: "30%",
            position: "relative",
            center: "calc(50% - 50px)",
            top: "50%"
          }}
        >
          <Loader
            type="ThreeDots"
            color="#00BFA5"
            height={90}
            width={90}
            timeout={300000}
          />
        </div>
      ); */
    const {
      profiles,
      totalCount,
      tags,
      revoked,
      upgradeValue,
      newRole,
      profilePreview,
      profileUpgradeDetails,
    } = this.state;
    return (
      <div>
        <div className="notification-popup">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="content">
          <Row>
            <Col xs={12} md={12}>
              <div className="page-title">
                <div className="float-left">
                  <h1 className="title">Members Management</h1>
                </div>
                <div className="float-right" style={{ marginTop: "2%" }}>
                  <form
                    className="topbar-search-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.getSearchedProfiles(1);
                    }}
                  >
                    <InputGroup className={"topbar-search open"}>
                      <InputGroupAddon addonType="append">
                        <i
                          className="i-magnifier"
                          onClick={() => this.getSearchedProfiles(1)}
                        ></i>
                      </InputGroupAddon>
                      <Input
                        style={{ position: "relative" }}
                        placeholder={"Search..."}
                        value={this.state.text}
                        name="text"
                        onChange={this.handleInputChange}
                      />
                      <span
                        onClick={this.cancelSearch}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "8px",
                          top: "12px",
                          zIndex: "100",
                          display:
                            this.state.searchQuery !== "" ? "block" : "none",
                        }}
                        className="fa fa-trash"
                      ></span>
                    </InputGroup>
                  </form>
                </div>
              </div>
              {/* /////// */}
              <div className="col-lg-12 col-xl-12 col-md-12 col-12">
                <section className="box ">
                  <div className="content-body">
                    <div className="row">
                      <div className="col-12">
                        <span
                          className="export-button"
                          onClick={() => this.exportData()}
                        >
                          <i className="fa fa-external-link"></i>&nbsp;Export
                        </span>
                        {this.state.isSearchResult ||
                        this.state.isFilterResult ? (
                          <span className="results-count">
                            {totalCount} results were found
                          </span>
                        ) : null}
                        <Table hover responsive className="members-table">
                          <thead>
                            <tr>
                              {/* <th className="fullName">Full Name</th> */}
                              <th>
                                <select
                                  className="fullName filter-col"
                                  id="inputGender"
                                  name="gender"
                                  onChange={this.handleGendersInputChange}
                                  value={this.state.filter.gender}
                                  required
                                >
                                  {genders.map((data, key) => {
                                    return (
                                      <option value={data} key={key}>
                                        {data}
                                      </option>
                                    );
                                  })}
                                </select>
                              </th>
                              <th>Info</th>
                              <th>Email</th>
                              <th>Interests/Expertises</th>
                              {/* <th>City</th> */}
                              <th>
                                <select
                                  id="inputGov"
                                  name="gov"
                                  className="filter-col"
                                  onChange={this.handleGovernorateInputChange}
                                  value={this.state.filter.country}
                                  required
                                >
                                  {govList.map((data, key) => {
                                    return (
                                      <option
                                        value={data}
                                        key={key}
                                        style={{
                                          color:
                                            data === "--> Tunisia <--" ||
                                            data === "--> Libya <--"
                                              ? "#D43272"
                                              : "initial",
                                        }}
                                        disabled={
                                          data === "--> Tunisia <--" ||
                                          data === "--> Libya <--"
                                        }
                                      >
                                        {data}
                                      </option>
                                    );
                                  })}
                                </select>
                              </th>
                              {/* <th className="centered-column-text">Role</th> */}
                              <th>
                                <select
                                  className="centered-column-text filter-col"
                                  id="inputRole"
                                  name="role"
                                  onChange={this.handleRolesInputChange}
                                  value={this.state.filter.role}
                                  required
                                >
                                  {roles.map((data, key) => {
                                    return (
                                      <option value={data} key={key}>
                                        {data}
                                      </option>
                                    );
                                  })}
                                </select>
                              </th>
                              <th>Added On</th>
                              <th>Block</th>
                              <th className="upgrade" colSpan="2">
                                Upgrade
                              </th>
                              <th>Delete User</th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log(profiles)}

                            {profiles.map((profile, key) => {
                              return (
                                <tr key={key}>
                                  <td
                                    className="user-inline-img text-cell"
                                    id={`Tooltip${key}`}
                                  >
                                    <img
                                      src={
                                        profile.image && profile.image.name
                                          ? `${SERVERDIR}Containers/img/download/${profile.image.name}`
                                          : `${IMGDIR}/images/profile/profile-general.jpg`
                                      }
                                      alt="user avatar"
                                      className="avatar-image img-inline"
                                    />
                                    <span
                                      className="clickable"
                                      onClick={
                                        (profile &&
                                          profile.upgradeRequest &&
                                          profile.upgradeRequest.length > 0 &&
                                          profile.upgradeRequest.filter(
                                            (obj) => {
                                              return obj.status === "Pending";
                                            }
                                          ).length > 0 &&
                                          profile.upgradeRequest.filter(
                                            (obj) => {
                                              return obj.status === "Pending";
                                            }
                                          )[0].newRole) === "host" ||
                                        (profile &&
                                          profile.upgradeRequest &&
                                          profile.upgradeRequest.length > 0 &&
                                          profile.upgradeRequest.filter(
                                            (obj) => {
                                              return obj.status === "Pending";
                                            }
                                          ).length > 0 &&
                                          profile.upgradeRequest.filter(
                                            (obj) => {
                                              return obj.status === "Pending";
                                            }
                                          )[0].newRole) === "teacher"
                                          ? () =>
                                              this.toggleModalProfile(profile)
                                          : null
                                      }
                                      style={{
                                        color: this.nameColor(
                                          profile &&
                                            profile.upgradeRequest &&
                                            profile.upgradeRequest.length > 0 &&
                                            profile.upgradeRequest.filter(
                                              (obj) => {
                                                return obj.status === "Pending";
                                              }
                                            ).length > 0 &&
                                            profile.upgradeRequest.filter(
                                              (obj) => {
                                                return obj.status === "Pending";
                                              }
                                            )[0].newRole
                                        ),
                                      }}
                                    >
                                      {profile && profile.fullName}
                                    </span>
                                    {/* <Tooltip
                                      key={key}
                                      placement="right"
                                      isOpen={this.state[`Tooltip${key}`]}
                                      target={`Tooltip${key}`}
                                      toggle={()=>this.toggleTooltip(`Tooltip${key}`)}
                                    >
                                      {profile.lastSeen ? `Last Seen: ${moment(profile.lastSeen).format(
                                      "DD/MM/YYYY"
                                    )}`:"Never"}
                                    </Tooltip> */}
                                  </td>
                                  <td
                                    onClick={() => {
                                      this.setState({
                                        profileToPreview: profile,
                                        modalPreviewProfileVisible: true,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-info clickable purple"></i>
                                  </td>
                                  <td className="text-cell email-cell">
                                    {profile.email}
                                  </td>
                                  <td className="centered-column-text clickable">
                                    {profile.roles &&
                                    profile.roles.some(
                                      (item) => item.name === "teacher"
                                    ) ? (
                                      <div className="purple">
                                        <span
                                          onClick={() =>
                                            this.toggleModalTags(
                                              profile.interests &&
                                                profile.interests.length > 0
                                                ? profile.interests
                                                : profile.interest,
                                              "Interests"
                                            )
                                          }
                                        >
                                          {profile.interests &&
                                          profile.interests.length > 0
                                            ? profile.interests.length
                                            : profile.interest &&
                                              profile.interest.length > 0
                                            ? profile.interest.length
                                            : 0}{" "}
                                          Interests |{" "}
                                        </span>
                                        <span
                                          onClick={() =>
                                            this.toggleModalTags(
                                              profile && profile.expert,
                                              "Expertises"
                                            )
                                          }
                                        >
                                          {profile.expert &&
                                          profile.expert.length > 0
                                            ? profile.expert &&
                                              profile.expert.length
                                            : "0"}{" "}
                                          Expertises
                                        </span>
                                      </div>
                                    ) : (
                                      <span
                                        onClick={() =>
                                          this.toggleModalTags(
                                            profile.interests &&
                                              profile.interests.length > 0
                                              ? profile.interests
                                              : profile.interest,
                                            "Interests"
                                          )
                                        }
                                      >
                                        {profile.interests &&
                                        profile.interests.length > 0
                                          ? profile.interests.length
                                          : profile.interest &&
                                            profile.interest.length > 0
                                          ? profile.interest.length
                                          : 0}{" "}
                                        Interests
                                      </span>
                                    )}
                                  </td>
                                  <td className="centered-column-text text-cell">
                                    {profile && profile.country}
                                  </td>
                                  <td className="centered-column-text">
                                    <span
                                      style={{
                                        backgroundColor: this.badgeColor(
                                          ["teacher", "host"].every((i) =>
                                            profile.roles
                                              .map((a) => a.name)
                                              .includes(i)
                                          )
                                            ? "teacher/host"
                                            : profile.roles &&
                                              profile.roles.some(
                                                (item) =>
                                                  item.name === "teacher"
                                              )
                                            ? "teacher"
                                            : profile.roles &&
                                              profile.roles.some(
                                                (item) => item.name === "host"
                                              )
                                            ? "host"
                                            : profile.roles &&
                                              profile.roles.some(
                                                (item) => item.name === "admin"
                                              )
                                            ? "admin"
                                            : "student",
                                          profile &&
                                            profile.upgradeRequest &&
                                            profile.upgradeRequest.length > 0 &&
                                            profile.upgradeRequest.filter(
                                              (obj) => {
                                                return obj.status === "Pending";
                                              }
                                            ).length > 0 &&
                                            profile.upgradeRequest.filter(
                                              (obj) => {
                                                return obj.status === "Pending";
                                              }
                                            )[0].newRole
                                        ),
                                      }}
                                      className="badge"
                                    >
                                      {["teacher", "host"].every((i) =>
                                        profile.roles
                                          .map((a) => a.name)
                                          .includes(i)
                                      )
                                        ? "teacher / host"
                                        : profile.roles &&
                                          profile.roles.some(
                                            (item) => item.name === "teacher"
                                          )
                                        ? "teacher"
                                        : profile.roles &&
                                          profile.roles.some(
                                            (item) => item.name === "host"
                                          )
                                        ? "host"
                                        : profile.roles &&
                                          profile.roles.some(
                                            (item) => item.name === "admin"
                                          )
                                        ? "admin"
                                        : "student"}
                                    </span>
                                  </td>
                                  <td className="centered-column-text">
                                    {moment(profile.createdAt).format(
                                      "D MMM YYYY"
                                    )}
                                  </td>
                                  <td>
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color: profile.revoked
                                          ? "red"
                                          : "green",
                                        padding: "5px",
                                      }}
                                      onClick={() =>
                                        this.openModalBlock(
                                          key,
                                          profile.revoked
                                        )
                                      }
                                    >
                                      <i
                                        className={
                                          profile.revoked
                                            ? "fa fa-toggle-on"
                                            : "fa fa-toggle-off"
                                        }
                                        style={{ fontSize: "20px" }}
                                      ></i>
                                    </span>
                                  </td>
                                  <td className="text-center pr-0">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          profile &&
                                          profile.upgradeRequest &&
                                          profile.upgradeRequest.length > 0 &&
                                          profile.upgradeRequest.filter(
                                            (obj) => {
                                              return obj.status === "Pending";
                                            }
                                          ).length > 0
                                            ? "green"
                                            : "#CFCFCF",
                                        padding: "5px",
                                      }}
                                      onClick={
                                        profile &&
                                        profile.upgradeRequest &&
                                        profile.upgradeRequest.length > 0 &&
                                        profile.upgradeRequest.filter((obj) => {
                                          return obj.status === "Pending";
                                        }).length > 0
                                          ? (e) =>
                                              this.toggleModalConfirmAction(
                                                profile,
                                                true
                                              )
                                          : null
                                      }
                                    >
                                      <i
                                        className="fa fa-check"
                                        style={{ fontSize: "20px" }}
                                      ></i>
                                    </span>
                                  </td>
                                  <td className="text-center pl-0">
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        color:
                                          profile &&
                                          profile.upgradeRequest &&
                                          profile.upgradeRequest.length > 0 &&
                                          profile.upgradeRequest.filter(
                                            (obj) => {
                                              return obj.status === "Pending";
                                            }
                                          ).length > 0
                                            ? "red"
                                            : "#CFCFCF",
                                        padding: "5px",
                                      }}
                                      onClick={
                                        profile &&
                                        profile.upgradeRequest &&
                                        profile.upgradeRequest.filter((obj) => {
                                          return obj.status === "Pending";
                                        }).length > 0
                                          ? (e) =>
                                              this.toggleModalConfirmAction(
                                                profile,
                                                false
                                              )
                                          : null
                                      }
                                    >
                                      <i
                                        className="fa fa-times"
                                        style={{ fontSize: "20px" }}
                                      ></i>
                                    </span>
                                  </td>
                                  <td className="text-center">
                                    <i
                                      className="clickable fa fa-trash-o text-danger"
                                      style={{ fontSize: "20px" }}
                                      onClick={() => this.openModalDelete(key)}
                                    ></i>
                                  </td>
                                </tr>
                              );
                            })}
                            {profiles.length === 0 && (
                              <tr className="text-center">
                                <td colSpan="10">
                                  <b>Oops! no data found</b>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              {/* /////// */}
              {this.state.count > 1 ? (
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.count}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.active - 1}
                  forcePage={this.state.active - 1}
                />
              ) : null}
            </Col>
          </Row>
        </div>
        <div>
          <ModalConfirmation
            isOpen={this.state.modalDelete}
            toggle={this.toggleModalDelete}
            disabledButton={false}
            confirm={this.handleDeleteProfile}
            cancel={this.toggleModalDelete}
            title={"Delete User"}
            body={
              "Are you sure you want to delete this user? This process cannot be undone"
            }
            confirmText={"Confirm"}
          />
        </div>

        <div>
          <ModalConfirmation
            isOpen={this.state.modalChangeRole}
            toggle={this.toggleModalConfirmAction}
            disabledButton={false}
            confirm={this.handleChangeRole}
            cancel={this.toggleModalConfirmAction}
            title={"Upgrade User"}
            body={
              upgradeValue
                ? `Are you sure you want to upgrade this user to become a ${
                    newRole === "teacher" ? "tutor" : newRole
                  } ?`
                : `Are you sure you want to refuse this user's request to upgrade to a ${
                    newRole === "teacher" ? "tutor" : newRole
                  } ?`
            }
            confirmText={upgradeValue ? "Upgrade" : "Refuse"}
          />
        </div>
        <div>
          <ModalConfirmation
            isOpen={this.state.modalBlock}
            toggle={this.openModalblock}
            disabledButton={false}
            confirm={this.toggleBlock}
            cancel={this.closeModalBlock}
            title={revoked ? "Unblock User" : "Block User"}
            body={
              revoked
                ? "Are you sure you want to unblock this User?"
                : "Are you sure you want to block this User?"
            }
            confirmText={revoked ? "Unblock" : "Block"}
          />
        </div>
        <div>
          <ModalTags
            isOpen={this.state.modalTags}
            toggle={this.toggleModalTags}
            close={this.closeModalTags}
            title={this.state.modalTagsTitle}
            tags={tags}
          />
        </div>
        <Modal isOpen={this.state.modalProfile} toggle={this.openModalProfile}>
          <ModalHeader
            style={{ padding: "0.5rem 0.5rem" }}
            toggle={this.openModalProfile}
          >
            <span style={{ fontWeight: "bold" }}>
              {profilePreview && profilePreview.fullName}
            </span>
          </ModalHeader>
          <ModalBody className="p-3">
            <div className="col-12">
              <div className="row uprofile">
                <div className="uprofile-image col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12">
                  <img
                    alt=""
                    src={
                      profilePreview &&
                      profilePreview.image &&
                      profilePreview.image.name
                        ? `${SERVERDIR}Containers/img/download/${profilePreview.image.name}`
                        : `${IMGDIR}/images/profile/profile-general.jpg`
                    }
                    className="img-fluid"
                  />
                </div>
                <div className="uprofile-name col-xl-10 col-lg-9 col-md-9 col-sm-8 col-12">
                  <h3 className="uprofile-owner">
                    <a href="#!">{profilePreview && profilePreview.fullName}</a>
                  </h3>
                  <div className="clearfix"></div>
                  {profileUpgradeDetails.newRole === "teacher" ? (
                    <p className="uprofile-title">
                      {profileUpgradeDetails &&
                        profileUpgradeDetails.data &&
                        profileUpgradeDetails.data.profession}
                    </p>
                  ) : null}
                  <div className="clearfix"></div>

                  <p className="uprofile-title">
                    {profileUpgradeDetails.newRole === "teacher"
                      ? profileUpgradeDetails &&
                        profileUpgradeDetails.data &&
                        profileUpgradeDetails.data.phone
                      : profileUpgradeDetails &&
                        profileUpgradeDetails.profile &&
                        profileUpgradeDetails.profile.phone}
                  </p>
                </div>
              </div>
              <div className="row profile-details">
                <p>
                  {profileUpgradeDetails &&
                    profileUpgradeDetails.data &&
                    profileUpgradeDetails.data.description}
                </p>
                <p>
                  {profileUpgradeDetails &&
                    profileUpgradeDetails.profile &&
                    profileUpgradeDetails.profile.description}
                </p>

                {profileUpgradeDetails &&
                profileUpgradeDetails.expert &&
                profileUpgradeDetails.expert.length > 0 ? (
                  <div>
                    <hr />
                    <h3 className="font-weight-bold">Expertises</h3>
                    {this.renderExpertiseTags(
                      profileUpgradeDetails && profileUpgradeDetails.expert
                    )}
                  </div>
                ) : null}
                {profileUpgradeDetails &&
                profileUpgradeDetails.data &&
                profileUpgradeDetails.data.education &&
                profileUpgradeDetails.data.education.length > 0 ? (
                  <div>
                    <hr />
                    <h3 className="font-weight-bold">Education</h3>
                    {this.renderStringTags(
                      profileUpgradeDetails &&
                        profileUpgradeDetails.data &&
                        profileUpgradeDetails.data.education
                    )}
                  </div>
                ) : null}
                {profileUpgradeDetails &&
                profileUpgradeDetails.data &&
                profileUpgradeDetails.data.competencies &&
                profileUpgradeDetails.data.competencies.length > 0 ? (
                  <div>
                    <hr />
                    <h3 className="font-weight-bold">Competencies</h3>
                    {this.renderStringTags(
                      profileUpgradeDetails &&
                        profileUpgradeDetails.data &&
                        profileUpgradeDetails.data.competencies
                    )}
                  </div>
                ) : null}
                {profileUpgradeDetails &&
                profileUpgradeDetails.data &&
                profileUpgradeDetails.data.experience &&
                profileUpgradeDetails.data.experience.length > 0 ? (
                  <div>
                    <hr />
                    <h3 className="font-weight-bold">Experience</h3>
                    {this.renderStringTags(
                      profileUpgradeDetails &&
                        profileUpgradeDetails.data &&
                        profileUpgradeDetails.data.experience
                    )}
                  </div>
                ) : null}
                {profileUpgradeDetails &&
                profileUpgradeDetails.data &&
                profileUpgradeDetails.data.subjects &&
                profileUpgradeDetails.data.subjects.length > 0 ? (
                  <div>
                    <hr />
                    <h3 className="font-weight-bold">Subjects</h3>
                    {this.renderStringTags(
                      profileUpgradeDetails &&
                        profileUpgradeDetails.data &&
                        profileUpgradeDetails.data.subjects
                    )}
                  </div>
                ) : null}
                {profileUpgradeDetails &&
                profileUpgradeDetails.data &&
                profileUpgradeDetails.data.certifications &&
                profileUpgradeDetails.data.certifications.length > 0 ? (
                  <div>
                    <hr />
                    <h3 className="font-weight-bold">Certifications</h3>
                    {this.renderStringTags(
                      profileUpgradeDetails &&
                        profileUpgradeDetails.data &&
                        profileUpgradeDetails.data.certifications
                    )}
                  </div>
                ) : null}
                {profileUpgradeDetails &&
                profileUpgradeDetails.newRole === "teacher" &&
                profileUpgradeDetails.data &&
                profileUpgradeDetails.data.attachments &&
                profileUpgradeDetails.data.attachments.length > 0 ? (
                  <div>
                    <hr />
                    <h3 className="font-weight-bold">
                      Certifications attached files
                    </h3>
                    {this.renderFileNames(
                      profileUpgradeDetails &&
                        profileUpgradeDetails.data &&
                        profileUpgradeDetails.data.attachments
                    )}
                  </div>
                ) : null}
              </div>
              {profileUpgradeDetails &&
              profileUpgradeDetails.newRole === "host" &&
              profileUpgradeDetails &&
              profileUpgradeDetails.room &&
              profileUpgradeDetails.room.name &&
              profileUpgradeDetails.room.name.length > 0 ? (
                <div>
                  <hr />
                  <div className="row uprofile">
                    <div className="uprofile-image col-xl-2 col-lg-3 col-md-3 col-sm-4 col-12">
                      <img
                        alt=""
                        src={
                          profileUpgradeDetails &&
                          profileUpgradeDetails.room &&
                          profileUpgradeDetails.room.logo &&
                          profileUpgradeDetails.room.logo.name
                            ? `${SERVERDIR}Containers/img/download/${profileUpgradeDetails.room.logo.name}`
                            : `${IMGDIR}/images/profile/profile-general.jpg`
                        }
                        className="img-fluid"
                      />
                    </div>
                    <div className="uprofile-name col-xl-10 col-lg-9 col-md-9 col-sm-8 col-12">
                      <h3 className="uprofile-owner">
                        <a href="#!">
                          {profileUpgradeDetails &&
                            profileUpgradeDetails.room &&
                            profileUpgradeDetails.room.name}
                        </a>
                      </h3>
                      <div className="clearfix"></div>
                      <p className="uprofile-list">
                        <span>
                          <i className="fa fa-users"></i>{" "}
                          {profileUpgradeDetails &&
                            profileUpgradeDetails.room &&
                            profileUpgradeDetails.room.size}{" "}
                          Places maximum
                        </span>
                        <span>
                          <i className="fa fa-calendar-o"></i>{" "}
                          {moment(
                            profileUpgradeDetails &&
                              profileUpgradeDetails.room &&
                              profileUpgradeDetails.room.from
                          ).format("D/MM/YYYY")}
                          {" - "}
                          {moment(
                            profileUpgradeDetails &&
                              profileUpgradeDetails.room &&
                              profileUpgradeDetails.room.to
                          ).format("D/MM/YYYY")}
                        </span>
                        <span>
                          <i className="fa fa-map-marker"></i>{" "}
                          {profileUpgradeDetails &&
                            profileUpgradeDetails.room &&
                            profileUpgradeDetails.room.address &&
                            profileUpgradeDetails.room.address.name}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="row profile-details">
                    <p>
                      {profileUpgradeDetails &&
                        profileUpgradeDetails.room &&
                        profileUpgradeDetails.room.description}
                    </p>

                    {profileUpgradeDetails &&
                    profileUpgradeDetails.room &&
                    profileUpgradeDetails.room.equipments &&
                    profileUpgradeDetails.room.equipments.length > 0 ? (
                      <div>
                        <hr />
                        <h3 className="font-weight-bold">Equipement</h3>
                        {this.renderStringTags(
                          profileUpgradeDetails &&
                            profileUpgradeDetails.room &&
                            profileUpgradeDetails.room.equipments
                        )}
                      </div>
                    ) : null}
                    {profileUpgradeDetails &&
                    profileUpgradeDetails.room &&
                    profileUpgradeDetails.room.album &&
                    profileUpgradeDetails.room.album.length > 0 ? (
                      <div>
                        <hr />
                        <h3 className="font-weight-bold">
                          Classroom's pictures
                        </h3>
                        {this.renderImageNames(
                          profileUpgradeDetails &&
                            profileUpgradeDetails.room &&
                            profileUpgradeDetails.room.album
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              style={{ height: "35px", width: "98px", padding: "0px" }}
              color="primary"
              onClick={this.openModalProfile}
            >
              Ok
            </Button>
          </ModalFooter>
        </Modal>
        <ModalProfilePreview
          isOpen={this.state.modalPreviewProfileVisible}
          profile={this.state.profileToPreview}
          close={() => {
            this.setState({
              modalPreviewProfileVisible: false,
              profileToPreview: {},
            });
          }}
        />
      </div>
    );
  }
}

export default MembersManagement;
