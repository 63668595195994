import React from "react";
import { AppContext } from "./../../helpers/common.jsx";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Table,
    InputGroup,
    InputGroupAddon,
    Input
  } from "reactstrap";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import DateRangePicker from 'react-daterange-picker'
import 'react-daterange-picker/dist/css/react-calendar.css'
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { Multiselect } from 'multiselect-react-dropdown';
import API from './../../helpers/api.jsx';
import StarRatingComponent from 'react-star-rating-component';
import {tunGovList , libyaGovList , libyaGovBlackList} from './../../helpers/common'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
 

const moment = extendMoment(originalMoment);
const Handle = Slider.Handle;
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);
const govList = ["No option selected", ...tunGovList];

const handle = (props) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
      overlayStyle={{zIndex: 9999}}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

const wrapperStyle = { width: '100%', margin: "25px 0px 0px 5px",paddingRight: "15px"};
const priceMarks = {
  0: <strong>0 TND</strong>,
1000: <strong>1000&nbsp;TND</strong>
};



class Search extends React.Component {
  constructor(props) {
    super(props);
    const today = moment().utc();
    this.state = {
      modalSearch: false,
      value: "",
      searchQuery: "",
      sessionRating: 0,
      filter: {
        keyword: "",
        price: [0,1000],
        domains: [],
        location: "",
        note: 0,
        dateRange: moment.range(today.clone(), today.clone()),
      },
      prevFilter: {
        keyword: "",
        price: [0,1000],
        domains: [],
        location: "",
        note: 0,
        dateRange: moment.range(today.clone(), today.clone()),
      },
      prevDate: moment.range(today.clone(), today.clone()),
      dateRangeValue: moment.range(today.clone(), today.clone()),
      rangeValue: [0,1000],
      domainsList: [],
      country: '', region: '',
      isRangeSelected: false
    };
    this.multiselectRef = React.createRef();
  }


  selectCountry =(val)=> {
    this.setState({ country: val });
  }
 
  selectRegion =(val)=> {
    this.setState({ region: val });
    this.setState(
      {
        filter: {
          ...this.state.filter,
          location: val
        }
      }
    );
  }


  componentWillMount = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    API.defaults.headers.common["Authorization"] = accessToken;
    await this.getDomains();
  }

  getDomains = async () => {
    const accessToken = localStorage.getItem("WeCodeLand_jwt");
    const domains = await API.get(
      `Domains?access_token=${accessToken}`
    );

    if (domains.status === 200) {
      console.log('domains :', domains.data);
      this.setState({ domainsList: domains.data })

    }
    else {
      console.log("error");

    }
  }

  openModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
      prevFilter: this.state.filter,
      regionKey: Date.now(),
      prevCountry: this.state.country,
      
    },()=>{
      this.setState({
        country: "Tunisia",
      },()=>{
        this.setState({
          country: this.state.prevCountry
        })
      })
    });
  };
  updateInputValue = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };
  handleGovernorateInputChange = e => {
    e.preventDefault();
    const target = e.target;

    this.setState(
      {
        filter: {
          ...this.state.filter,
          location: target.value
        }
      }
    );
  };

  handlePriceInputChange = (value) => {

    this.setState(
      {
        filter: {
          ...this.state.filter,
          price: value,
        },
      }
    );
  };
  handleDomainsInputChange = () => {

    this.setState(
      {
        filter: {
          ...this.state.filter,
          domains: this.multiselectRef.current.getSelectedItems(),
        },
      }
    );
  };

  handleSearchInput = async e => {
    const target = e.target;

    this.setState({
      filter: {
        ...this.state.filter,
        keyword : target.value
      }
    });
  };
  resetKeyword = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        keyword: "",
      }
    },()=>this.props.getSearchedResult(1,this.filterContent(),this.state.filter.dateRange));
  }
  resetNote = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        note: 0,
      }
    },()=>this.props.getSearchedResult(1,this.filterContent(),this.state.filter.dateRange));
};
  resetDomains = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        domains: [],
      }
    },()=>this.props.getSearchedResult(1,this.filterContent(),this.state.filter.dateRange));
};

resetPrice = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        price: [0,1000],
      }
    },()=>this.props.getSearchedResult(1,this.filterContent(),this.state.filter.dateRange));
};
resetLocation = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        location: ""
      },
      region: "",
      country: ""
    },()=>this.props.getSearchedResult(1,this.filterContent(),this.state.filter.dateRange));
};
resetDate = () => {
  this.setState({
    isRangeSelected: false,
    dateRangeValue: moment.range(moment().utc().clone(), moment().utc().clone()),
    filter: {
      ...this.state.filter,
      dateRange: moment.range(moment().utc().clone(), moment().utc().clone()),
    }
  },()=>this.props.getSearchedResult(1,this.filterContent(),this.state.filter.dateRange));
};

resetFields = () =>{
  const today = moment().utc();
  this.setState({
    isRangeSelected: false,
    filter: {
      keyword: "",
      price: [0,1000],
      domains: [],
      location: "",
      note: 0,
      dateRange: moment.range(today.clone(), today.clone()),
    },
    dateRangeValue: moment.range(today.clone(), today.clone()),
  });
}

confirmSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
      prevFilter: this.state.filter
    },()=>this.props.getSearchedResult(1,this.filterContent(),this.state.filter.dateRange,this.state.isRangeSelected));
  };

  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
      filter: this.state.prevFilter,
      dateRangeValue: this.state.prevFilter.dateRange
    });
  };
  onSelectDateRange = (dateRangeValue, states) => {
    this.setState({isRangeSelected : true})
    console.log("dateRangeValue.start.format()",dateRangeValue.start.format("YYYY-MM-DD"));
    console.log("dateRangeValue.end.format()",dateRangeValue.end.format("YYYY-MM-DD"));
    console.log("dateRangeValue",dateRangeValue);
    this.setState({ dateRangeValue, states });
    /* if(!(dateRangeValue.start.format("YYYY-MM-DD") === dateRangeValue.end.format("YYYY-MM-DD") && dateRangeValue.start.format("YYYY-MM-DD") === moment().utc().format("YYYY-MM-DD")))
    { */
      this.setState(
        {
          filter: {
            ...this.state.filter,
            dateRange: dateRangeValue,
          },
        }
      );
    /* } */
  };

  onToggleDateRangePicker = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  renderSelectionValue = () => {
    /* if(!(this.state.dateRangeValue.start.format("YYYY-MM-DD") === this.state.dateRangeValue.end.format("YYYY-MM-DD") && this.state.dateRangeValue.start.format("YYYY-MM-DD") === moment().utc().format("YYYY-MM-DD"))) */
    if (this.state.isRangeSelected)
    {
    console.log(this.state.dateRangeValue.start);
    
      return (
      <div>
        <div>Selection</div>
        <h5>
        {this.state.dateRangeValue.start.format("YYYY-MM-DD")}
        {" - "}
        {this.state.dateRangeValue.end.format("YYYY-MM-DD")}
        </h5>
      </div>
    );}
    else {
      return null;
    }
  };

  checkProperties = (obj) => {
    for (var key in obj) {
      if (obj[key] !== null && obj[key] !== "") return false;
    }
    return true;
  };

  filterContent = () => {
    var {filter} = this.state;
    let filterToSend = {}    
    if(!(filter && filter.price && filter.price[0] === 0 && filter.price[1] === 1000)){
      filterToSend.price = filter.price;
    }
    if(filter && filter.keyword !== ""){
      filterToSend.keyword = filter.keyword;
    }
    if(filter && filter.location !== ""){
      filterToSend.location = filter.location;
    }
    if(filter && filter.note > 0){
      filterToSend.note = filter.note;
    }
    if(filter && filter.domains && filter.domains.length > 0){
      filterToSend.domains = filter.domains;
    }
    return filterToSend;
  }

  renderObjectTags = (tagsList) => {
    if (tagsList && tagsList.length > 0) {
      return (
        <div className="d-flex filter-tags">
          {tagsList.map((tag, index) => (
            <li key={index} className="tag">
              <span className="tag-title">&nbsp;{tag.name}&nbsp;</span>
            </li>
          ))}
        </div>
      );
    }
    else {
      return null;
    }
  };

  onSessionStarClick=(nextValue, prevValue, name, e)=> {
    const xPos = (e.pageX - e.currentTarget.getBoundingClientRect().left) / e.currentTarget.offsetWidth;

    if (xPos <= 0.5) {
      nextValue -= 0.5;
    }

    console.log('name: %s, nextValue: %s, prevValue: %s', name, nextValue, prevValue);
    // console.log(e);
    this.setState(
      {
        filter: {
          ...this.state.filter,
          note: nextValue,
        },
      }
    );
  }

  render() {
    const {filter,country, region} = this.state;
  return (
  <div>
                  <form
                    className="topbar-search-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      this.props.getSearchedResult(1,this.filterContent(),this.state.filter.dateRange);
                    }}
                  >
                    <InputGroup className={"topbar-search open"}>
                      <InputGroupAddon addonType="append">
                        <i
                          className="i-magnifier"
                          onClick={() => this.props.getSearchedResult(1,this.filterContent(),this.state.filter.dateRange)}
                        ></i>
                      </InputGroupAddon>
                      <Input
                        className="search-input"
                        placeholder={"Search..."}
                        value={this.state.filter.keyword}
                        onChange={this.handleSearchInput}
                      />
                      <span
                        onClick={()=>{this.resetKeyword()}}
                        style={{
                          display:
                            this.state.filter.keyword !== "" ? "block" : "none",
                        }}
                        className="fa fa-trash cancel-search"
                      ></span>
                    </InputGroup>
                  </form>
                  <div className="advanced-search-text">
                    <span
                      onClick={() =>
                        this.openModalSearch()
                      }
                    >
                      Advanced Search
                    </span>
                    <div className="d-block">
                      {(!(filter && filter.price && filter.price[0] === 0 && filter.price[1] === 1000)) ? (
                        <div>
                          <span>&nbsp;Price : {this.state.filter.price && this.state.filter.price[0]} TND - {this.state.filter.price && this.state.filter.price[1]} TND</span>
                          <span
                            className="fa fa-times close-tag"
                            onClick={this.resetPrice}
                          ></span>
                        </div>
                      ) : null}
                      {(filter && filter.location !== "") ? (
                        <div>
                          <span>&nbsp;Location : {this.state.filter.location}</span>
                          <span
                            className="fa fa-times close-tag"
                            onClick={this.resetLocation}
                          ></span>
                        </div>
                      ) : null}
                      {(filter && filter.note > 0) ? (
                        <div>
                          <span>&nbsp;Rating : {this.state.filter.note}</span>
                          <span
                            className="fa fa-times close-tag"
                            onClick={this.resetNote}
                          ></span>
                        </div>
                      ) : null}
                      {(filter && filter.domains && filter.domains.length > 0) ? (
                        <div className="d-inline-flex">
                          &nbsp;Domains : {this.renderObjectTags(filter.domains)}
                          <span
                            className="fa fa-times close-tag pt-1"
                            onClick={this.resetDomains}
                          ></span>
                        </div>
                      ) : null}
                      {/* (!(this.state.dateRangeValue.start.format("YYYY-MM-DD") === this.state.dateRangeValue.end.format("YYYY-MM-DD") && this.state.dateRangeValue.start.format("YYYY-MM-DD") === moment().utc().format("YYYY-MM-DD"))) */ this.state.isRangeSelected ?
                       <div className="date-filter">{"Date : "} {this.state.dateRangeValue.start.format("YYYY-MM-DD")}
                       {" - "}
                       {this.state.dateRangeValue.end.format("YYYY-MM-DD")} 
                       <span
                            className="fa fa-times close-tag"
                            onClick={this.resetDate}
                       ></span>
                       </div>
                      : null}
                      {(filter && filter.domains && filter.domains.length > 0) || (/* !(this.state.dateRangeValue.start.format("YYYY-MM-DD") === this.state.dateRangeValue.end.format("YYYY-MM-DD") && this.state.dateRangeValue.start.format("YYYY-MM-DD") === moment().utc().format("YYYY-MM-DD")) */ this.state.isRangeSelected) || (filter && filter.location !== "") || (!(filter && filter.price && filter.price[0] === 0 && filter.price[1] === 1000)) || (filter && filter.note > 0)? (
                        <div>
                        <span onClick={()=>{this.props.cancelSearch();this.resetFields()}}>&nbsp;Clear filter</span>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <Modal
                    isOpen={this.state.modalSearch}
                    toggle={this.toggleModalSearch}
                    className={this.props.className}
                    backdrop={ 'static' }
                    keyboard={false}
                >
                    <ModalHeader
                    toggle={this.toggleModalSearch}
                    >
                    <span className="bold">Advanced Search</span>
                    </ModalHeader>
                    <ModalBody>
                    <div id="home-search">
                        <div className="form-group">
                        <label className="form-label bold" htmlFor="field-1">
                            Price per person
                        </label>
                        <br />
                        <label>Please Slide to select a price range</label>
                        <br/>
                        <span> <span className="bold">Min: </span>{this.state.filter.price && this.state.filter.price[0]+" - "}<span className="bold">Max: </span>{this.state.filter && this.state.filter.price[1]}</span>
                        <div style={wrapperStyle}>
                        <Range
                          value={this.state.filter.price}
                          onChange={this.handlePriceInputChange}
                          allowCross={false}
                          min={0}
                          max={1000}
                          defaultValue={this.state.filter.price}
                          handle={handle}
                          marks={priceMarks}
                          {...this.props}
                        />
                        </div>
                        </div>
                        <br/>
                        <div className="form-group">
                          <label className="form-label bold" htmlFor="field-1">
                            Location
                          </label>
                          <br />
                          {/* <div>
                                <select
                                  id="inputGov"
                                  className="filter-col"
                                  onChange={this.handleGovernorateInputChange}
                                  value={this.state.filter.location}
                                  required
                                >
                                  {govList.map((data, key) => {
                                    return (
                                      <option value={data === "No option selected" ? "" : data} key={key}>
                                        {data}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div> */}

                              <div>
                                <CountryDropdown
                                  value={country}
                                  whitelist={["TN","LY"]}
                                  onChange={(val) => this.selectCountry(val)} />
                                <RegionDropdown
                                  key={this.state.regionKey}
                                  country={country}
                                  value={region}
                                  disableWhenEmpty={true}
                                  defaultOptionLabel={region}
                                  blacklist={{LY:libyaGovBlackList}}
                                  onChange={(val) => this.selectRegion(val)}
                                  customOptions={country === "Libya" ? libyaGovList : country === "Tunisia" ? tunGovList : []} />
                              </div>

                        </div>
                        <div className="form-group">
                          <label className="form-label bold" htmlFor="field-1">
                            Keyword
                          </label>
                          <br />
                          <input
                            className="w-100"
                            type="text"
                            value={this.state.filter.keyword}
                            onChange={this.handleSearchInput}
                            placeholder="Insert keywords you are looking for"
                          />                        
                        </div>
                        <div className="form-group">
                              <label htmlFor="imputDomains" className="form-label bold">Domain(s)</label>
                              <Multiselect
                                options={this.state.domainsList} // Options to display in the dropdown
                                onSelect={this.handleDomainsInputChange} // Function will trigger on select event
                                onRemove={this.handleDomainsInputChange} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                ref={this.multiselectRef}
                                placeholder="Select domain(s)"
                                avoidHighlightFirstOption={true}
                                selectedValues={this.state.filter.domains}
                              />
                        </div>
                        <div className="form-group">
                              <label htmlFor="sessionRating" className="form-label bold">Session rating</label>
                              <div style={{fontSize: 30}}>
                            <StarRatingComponent
                                name="app6"
                                starColor="#D43272"
                                emptyStarColor="#D43272"
                                value={this.state.filter.note}
                                onStarClick={this.onSessionStarClick}
                                renderStarIcon={(index, value) => {
                                return (
                                    <span>
                                    <i className={index <= value ? 'fa fa-star' : 'fa fa-star-o'} />
                                    </span>
                                );
                                }}
                                renderStarIconHalf={() => {
                                return (
                                    <span>
                                    <span style={{position: 'absolute'}}><i className="fa fa-star-o" /></span>
                                    <span><i className="fa fa-star-half-o" /></span>
                                    </span>
                                );
                                }} />
                            </div>
                        </div>
                        <div className="form-group">
                          <label className="form-label bold" htmlFor="field-1">
                            Date
                          </label>
                          <br />
                          <label>Please Select a date range</label>
                          <div>{this.renderSelectionValue()}</div>
                          <div>
                            <input
                              className="btn"
                              type="button"
                              value="Toggle date picker"
                              onClick={this.onToggleDateRangePicker}
                            />
                          </div>

                          {this.state.isOpen && (
                            <DateRangePicker
                              value={this.state.dateRangeValue}
                              onSelect={this.onSelectDateRange}
                              singleDateRange={true}
                              firstOfWeek={1}
                              numberOfCalendars={2}
                              selectionType="range"
                              initialFromValue= {false}
                            />
                          )}
                        </div>
                    </div>
                    </ModalBody>
                    <ModalFooter>
                    <span className="cancel-search-modal" onClick={this.toggleModalSearch}>Cancel</span>
                    <Button
                        className="confirm-modal"
                        color="primary"
                        onClick={this.confirmSearch}
                    >
                        Search
                    </Button>
                    </ModalFooter>
                </Modal>
                </div>);
  }
}
export default (props) => (
  <AppContext.Consumer>
    {(Helper) => <Search {...props} helper={Helper} />}
  </AppContext.Consumer>
);