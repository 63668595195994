import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isAuthorized} from './../helpers/common.jsx';

const PrivateRoute = ({component: Component,authorizedRoles, to, ...rest}) => {
    return (
        <Route {...rest} render={(props) => (
            isAuthorized(authorizedRoles)
            ? <Component {...props} />
            : <Redirect to={{
                    pathname: to,
                    state: { from: props.location }
                }} />
        )} />
    );
};

export default PrivateRoute;
